import startCase from 'lodash/startCase';
import useSWRImmutable from 'swr/immutable';

import { EnumsMediaScene } from '@lp-lib/api-service-client/public';

import { apiService } from '../../../services/api-service';
import { CelebrationEventType } from '../../../types/celebration';
import { type CelebrationEventGlobalSettings } from '../../../types/settings';
import { err2s } from '../../../utils/common';
import { useSettingsData } from '../../DynamicSettings/hooks/useSettingsData';
import { Loading } from '../../Loading';
import { MiniMediaUploader } from '../../MediaUploader/MiniMediaUploader';
import { CelebrationEventKnifeUtils } from './../CelebrationEventUtils';
import { CelebrationMessagePreview } from './../CelebrationSlackMessage';

function EventMedia(props: { mediaId: string; onDelete: () => void }) {
  const { mediaId, onDelete } = props;

  const {
    data: media,
    error,
    isValidating,
  } = useSWRImmutable(mediaId ? `/media/${mediaId}` : null, async () => {
    return (await apiService.media.getMedia(mediaId)).data.media;
  });

  if (isValidating) return <Loading />;
  if (error) return <div className='text-sms text-red-002'>{err2s(error)}</div>;
  if (!media) return null;

  return (
    <MiniMediaUploader
      uploaderId={mediaId}
      media={media}
      onDelete={onDelete}
      scene={EnumsMediaScene.MediaSceneProgramCelebrationsMessage}
      objectFit='cover'
    />
  );
}

function EventSettingsSection(props: {
  type: CelebrationEventType;
  settings: CelebrationEventGlobalSettings;
  onChange: (updated: CelebrationEventGlobalSettings) => void;
}) {
  const { type, settings, onChange } = props;
  const label = CelebrationEventKnifeUtils.Label(type);

  const handleUpdate = (updates: Partial<CelebrationEventGlobalSettings>) => {
    onChange({
      ...settings,
      ...updates,
    });
  };

  return (
    <section className='w-full flex'>
      <div className='w-1/3'>
        <h3 className='text-base font-bold'>
          Default {startCase(label)} Message
        </h3>
        <p className='mt-1.5 text-sms font-normal text-icon-gray'>
          The {label} celebration message that Luna Park sends to the channel
        </p>
        <h3 className='mt-4 text-base font-bold'>
          {startCase(label)} Message Media
        </h3>
        <div className='mt-2 flex gap-2.5 flex-wrap'>
          {settings.mediaIds.map((mediaId) => (
            <EventMedia
              key={mediaId}
              mediaId={mediaId}
              onDelete={() =>
                handleUpdate({
                  mediaIds: settings.mediaIds.filter((id) => mediaId !== id),
                })
              }
            />
          ))}
          <MiniMediaUploader
            // force to renew a new component
            key={`celebration-global-settings-media-after-${
              settings.mediaIds?.[settings.mediaIds.length - 1]
            }`}
            scene={EnumsMediaScene.MediaSceneProgramCelebrationsMessage}
            objectFit='cover'
            onUploadSuccess={(media) =>
              handleUpdate({
                mediaIds: [...settings.mediaIds, media.id],
              })
            }
          />
        </div>
      </div>
      <div className='flex-1 ml-14'>
        <CelebrationMessagePreview type={type} />
      </div>
    </section>
  );
}

export function CelebrationAdminPanelConfig(): JSX.Element | null {
  const {
    data: settings,
    isLoading,
    error,
    updateValue,
  } = useSettingsData('program-celebrations');

  if (isLoading) return <Loading />;
  if (error) return <div>{err2s(error)}</div>;
  if (!settings) return <div>Not Found!</div>;

  return (
    <div className='w-full px-10 pb-10 text-white flex justify-center'>
      <div className='w-full max-w-280'>
        <header className='text-2xl font-medium'>
          Birthdays and Celebrations Config
        </header>

        <div className='w-full'>
          <main className='mt-14 w-full flex flex-col gap-8'>
            <EventSettingsSection
              type={CelebrationEventType.Birthday}
              settings={settings.birthday}
              onChange={(updated) =>
                updateValue({
                  key: 'birthday',
                  value: updated,
                })
              }
            />
            <EventSettingsSection
              type={CelebrationEventType.WorkAnniversary}
              settings={settings.workAnniversary}
              onChange={(updated) =>
                updateValue({
                  key: 'workAnniversary',
                  value: updated,
                })
              }
            />
          </main>
        </div>
      </div>
    </div>
  );
}
