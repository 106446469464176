import { useSearchParams } from '@remix-run/react';
import { useNavigate } from '@remix-run/react';
import { format } from 'date-fns';
import capitalize from 'lodash/capitalize';
import { useCallback, useMemo } from 'react';
import Select from 'react-select';
import useSWR from 'swr';

import {
  type DtoChannelProgramLink,
  EnumsProgramLinkStatus,
  EnumsProgramType,
} from '@lp-lib/api-service-client/public';

import { useDebouncedValue } from '../../hooks/useDebouncedValue';
import { apiService } from '../../services/api-service';
import { type ProgramLinkFilters } from '../../services/api-service/channel.api';
import { err2s } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import type { Option } from '../common/Utilities';
import { Loading } from '../Loading';
import { OrganizationSelect } from '../Organization';
import { useBasicProgramFromLink } from './usePrograms';

function useProgramLinkFilters() {
  const [searchParams, setSearchParams] = useSearchParams();
  const filters = useMemo(() => {
    const filters: ProgramLinkFilters = {};
    if (searchParams.has('orgId')) {
      filters.orgId = searchParams.get('orgId') ?? undefined;
    }
    if (searchParams.has('type')) {
      filters.type = searchParams.get('type') ?? undefined;
    }
    if (searchParams.has('status')) {
      filters.status = searchParams.get('status') ?? undefined;
    }
    return filters;
  }, [searchParams]);

  const setFilters = useCallback(
    (filters: ProgramLinkFilters) => {
      setSearchParams((prev) => {
        for (const filterName in filters) {
          const value = filters[filterName as keyof ProgramLinkFilters];
          if (value === undefined) {
            prev.delete(filterName);
          } else {
            prev.set(filterName, String(value));
          }
        }
        return prev;
      });
    },
    [setSearchParams]
  );

  const clearFilters = useCallback(() => {
    setSearchParams((prev) => {
      prev.delete('orgId');
      prev.delete('type');
      prev.delete('status');
      return prev;
    });
  }, [setSearchParams]);

  return {
    filters,
    setFilters,
    clearFilters,
  };
}

// TODO(falcon): remove these once SelectEnum<T> is refactored.
const programTypeOptions = Object.values(EnumsProgramType).map((t) => ({
  label: t.toString(),
  value: t,
}));

function SelectProgramType(props: {
  value: EnumsProgramType | string | null;
  invalid?: boolean;
  onChange: (value: EnumsProgramType | null) => void;
  disabled?: boolean;
}): JSX.Element {
  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<EnumsProgramType>>({
        isError: props.invalid ?? false,
      }),
    [props.invalid]
  );
  const selected = useMemo(() => {
    return programTypeOptions.find((o) => o.value === props.value) ?? null;
  }, [props.value]);

  return (
    <Select<Option<EnumsProgramType>, false>
      classNamePrefix='select-box-v2'
      className='w-full text-xs'
      styles={styles}
      value={selected}
      options={programTypeOptions}
      onChange={(o) => props.onChange(o?.value ?? null)}
      isSearchable={false}
      isDisabled={props.disabled}
      placeholder='Program Type'
      isClearable
    />
  );
}

const programLinkStatusOptions = Object.values(EnumsProgramLinkStatus).map(
  (t) => ({
    label: t.toString(),
    value: t,
  })
);

function SelectProgramLinkStatus(props: {
  value: EnumsProgramLinkStatus | string | null;
  invalid?: boolean;
  onChange: (value: EnumsProgramLinkStatus | null) => void;
  disabled?: boolean;
}): JSX.Element {
  const styles = useMemo(
    () =>
      buildReactSelectStyles<Option<EnumsProgramLinkStatus>>({
        isError: props.invalid ?? false,
      }),
    [props.invalid]
  );
  const selected = useMemo(() => {
    return (
      programLinkStatusOptions.find((o) => o.value === props.value) ?? null
    );
  }, [props.value]);

  return (
    <Select<Option<EnumsProgramLinkStatus>, false>
      classNamePrefix='select-box-v2'
      className='w-full text-xs'
      styles={styles}
      value={selected}
      options={programLinkStatusOptions}
      onChange={(o) => props.onChange(o?.value ?? null)}
      isSearchable={false}
      isDisabled={props.disabled}
      placeholder='Status'
      isClearable
    />
  );
}

function ProgramLinkFilterControl(): JSX.Element {
  const { filters, setFilters } = useProgramLinkFilters();
  return (
    <div>
      <div className='flex items-center gap-4'>
        <div className='w-50'>
          <OrganizationSelect
            className='w-full'
            orgId={filters.orgId ?? null}
            onChange={(org) =>
              setFilters({
                ...filters,
                orgId: org?.id,
              })
            }
            isClearable
            noFakeOrg
          />
        </div>
        <div className='w-50'>
          <SelectProgramType
            value={filters.type ?? null}
            onChange={(p) =>
              setFilters({
                ...filters,
                type: p ?? undefined,
              })
            }
          />
        </div>
        <div className='w-50'>
          <SelectProgramLinkStatus
            value={filters.status ?? null}
            onChange={(p) =>
              setFilters({
                ...filters,
                status: p ?? undefined,
              })
            }
          />
        </div>
      </div>
    </div>
  );
}

function ProgramLinkItem(props: { link: DtoChannelProgramLink }) {
  const { link } = props;
  const navigate = useNavigate();
  const { data: program } = useBasicProgramFromLink(link);
  return (
    <tr
      key={link.id}
      className='text-left h-8 text-sms whitespace-nowrap cursor-pointer hover:bg-lp-gray-002'
      onClick={() => {
        navigate(
          `/admin/organizations/${link.organization?.id}/programs/${link?.id}`
        );
      }}
    >
      <td>{link.organization?.name}</td>
      <td className='hidden'>{link.organization?.id}</td>
      <td>#{link.channel?.name}</td>
      <td className='hidden'>{link.channel?.id}</td>
      <td>{program?.name}</td>
      <td className='hidden'>{link.id}</td>
      <td>
        {link.owner?.firstName} {link.owner?.lastName}
      </td>
      <td>{format(new Date(link.updatedAt), 'MM/dd/yyyy hh:mm:ss')}</td>
      <td>{capitalize(link.status)}</td>
    </tr>
  );
}

export function ProgramLinksList(): JSX.Element | null {
  const { filters } = useProgramLinkFilters();

  const {
    data: programLinks,
    error,
    isLoading,
  } = useSWR(
    { url: `/channels/program-links`, args: filters },
    async ({ args }) =>
      (await apiService.channel.allProgramLinks(args)).data.programLinks,
    {
      keepPreviousData: true,
    }
  );

  const { value: showLoading } = useDebouncedValue(isLoading, {
    settleAfterMs: 500,
  });

  const sorted = useMemo(
    () =>
      programLinks?.sort((a, b) => {
        const l = `${a.organization?.name ?? ''}-${a.channel?.name ?? ''}-${
          a.programType
        }`;
        const r = `${b.organization?.name ?? ''}-${b.channel?.name ?? ''}-${
          b.programType
        }`;
        return l.localeCompare(r);
      }),
    [programLinks]
  );

  if (error) return <div className=' text-red-002'>{err2s(error)}</div>;
  if (!sorted && isLoading) return <Loading />;

  const hasRows = sorted && sorted.length > 0;
  return (
    <div className='w-full px-10 text-white'>
      <header className='text-2xl font-medium'>Programs</header>

      <div className='my-4 flex items-center gap-4'>
        <ProgramLinkFilterControl />
        {showLoading && <Loading text='' />}
      </div>

      <div className='relative my-8 w-full overflow-x-auto scrollbar'>
        {showLoading && (
          <div className='absolute inset-0 bg-black bg-opacity-50 animate-pulse' />
        )}
        <table className='table table-fixed w-full'>
          <thead>
            <tr className='whitespace-nowrap'>
              <th className='w-1/6'>Organization</th>
              <th className='hidden'>Organization ID</th>
              <th className='w-1/6'>Channel</th>
              <th className='hidden'>Channel ID</th>
              <th className='w-1/6'>Program Type</th>
              <th className='hidden'>Program ID</th>
              <th className='w-1/6'>Owner</th>
              <th className='w-1/6'>Created at</th>
              <th className='w-1/6'>Status</th>
            </tr>
          </thead>
          <tbody>
            {hasRows && (
              <>
                {sorted.map((link) => (
                  <ProgramLinkItem key={link.id} link={link} />
                ))}
              </>
            )}
          </tbody>
        </table>
        {!hasRows && (
          <div className='my-10 w-full text-center text-white'>
            No program links found
          </div>
        )}
      </div>
    </div>
  );
}
