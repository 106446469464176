import { type Organization } from './organization';

export enum GlobalPromotionStatus {
  New = 'New',
  Configuring = 'Configuring',
  Scheduled = 'Scheduled',
  Completed = 'Completed',
}

export enum PromotionStatus {
  Active = 'Active',
  InActive = 'InActive',
}
export interface GlobalPromotion {
  id: string;
  title: string;
  startedAt: string;
  endedAt: string;
  createdAt: string;
  updatedAt: string;
  status: GlobalPromotionStatus;
  orgsCount: number;
  messageCampaignId: string;
}

export interface Promotion {
  id: string;
  orgId: string;
  globalPromotionId: string;
  status: PromotionStatus;
  createdAt: string;
  updatedAt: string;

  organization: Organization | null;
  globalPromotion: GlobalPromotion;
}
