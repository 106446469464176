import useSWR, { type SWRResponse } from 'swr';

import { apiService } from '../../services/api-service';
import { type GlobalPromotion, type Promotion } from '../../types/promotion';

export function useGlobalPromotions(): SWRResponse<GlobalPromotion[], Error> {
  return useSWR(
    '/global-promotions',
    async () =>
      (await apiService.promotion.queryGlobalPromotions()).data.globalPromotions
  );
}

export function useGlobalPromotion(
  id: Nullable<string>
): SWRResponse<GlobalPromotion, Error> {
  return useSWR(
    id ? ['/global-promotions', id] : null,
    async ([_, id]) =>
      (await apiService.promotion.getGlobalPromotionById(id)).data
        .globalPromotion
  );
}

export function usePromotions(props: {
  globalPromotionId: Nullable<string>;
}): SWRResponse<Promotion[], Error> {
  return useSWR(
    props.globalPromotionId
      ? ['/global-promotions/promotions', props.globalPromotionId]
      : null,
    async ([_, id]) =>
      (await apiService.promotion.getPromotionsByGlobalId(id)).data.promotions
  );
}
