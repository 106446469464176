import { Link } from '@remix-run/react';
import { format } from 'date-fns';
import useSWRImmutable from 'swr/immutable';

import { assertExhaustive } from '@lp-lib/game';

import { apiService } from '../../../services/api-service';
import {
  type GlobalPairingRound,
  GlobalPairingRoundStatus,
  type PairingParticipationStats,
} from '../../../types';
import { GamePackCover } from '../../Game/Utilities';
import { percentage } from '../utils';

const Badge = (props: { round: GlobalPairingRound }): JSX.Element | null => {
  let styles = {
    text: '',
    bgColor: '',
  };

  switch (props.round.status) {
    case GlobalPairingRoundStatus.New:
    case GlobalPairingRoundStatus.Configuring:
      styles = {
        text: 'CONFIGURING ...',
        bgColor: 'bg-lp-red-001',
      };
      break;
    case GlobalPairingRoundStatus.Scheduled:
      styles = {
        text: 'SCHEDULED',
        bgColor: 'bg-blue-005',
      };
      break;
    case GlobalPairingRoundStatus.InProgress:
      styles = {
        text: 'IN PROGRESS',
        bgColor: 'bg-lp-green-003',
      };
      break;
    case GlobalPairingRoundStatus.Completed:
      styles = {
        text: 'COMPLETED',
        bgColor: 'bg-secondary',
      };
      break;
    default:
      assertExhaustive(props.round.status);
  }

  return (
    <div
      className={`absolute bottom-0 w-full h-8 rounded-b-lg border border-secondary border-t-0 
        flex items-center justify-center text-white text-base font-bold ${styles.bgColor}`}
    >
      {styles.text}
    </div>
  );
};

const GlobalPairingRoundCover = (props: {
  round: GlobalPairingRound;
}): JSX.Element => {
  return (
    <div className='w-60 flex-shrink-0'>
      <GamePackCover pack={props.round.mainGamePack}>
        <Badge round={props.round} />
      </GamePackCover>
    </div>
  );
};

function useParticipationStats(
  globalRoundId: string
): PairingParticipationStats | undefined {
  const { data } = useSWRImmutable(
    `/global-pairing-rounds/${globalRoundId}/participation-stats`,
    async () =>
      (await apiService.pairing.getGlobalRoundParticipationStats(globalRoundId))
        .data.stats
  );
  return data;
}

export const GlobalPairingRoundSummary = (props: {
  round: GlobalPairingRound;
}): JSX.Element => {
  const { round } = props;
  const { mainGamePack: gamePack } = round;

  const participationStats = useParticipationStats(round.id);

  const showPercentage = [
    GlobalPairingRoundStatus.InProgress,
    GlobalPairingRoundStatus.Completed,
  ].includes(round.status);
  const showCreateMessageCampaignReminder =
    !round.messageCampaignId &&
    [
      GlobalPairingRoundStatus.New,
      GlobalPairingRoundStatus.Configuring,
      GlobalPairingRoundStatus.Scheduled,
    ].includes(round.status);

  return (
    <div className='flex flex-col'>
      <div className='text-2xl font-bold'>
        {gamePack?.name ? (
          gamePack.name
        ) : (
          <span className=' text-red-002'>ERROR</span>
        )}
      </div>
      <div className='text-base font-normal'>
        {format(new Date(round.startedAt), 'eee, MMM do yyyy')} -{' '}
        {format(new Date(round.endedAt), 'eee, MMM do yyyy')}
      </div>
      <div className='text-base'>
        <Link
          to={`/admin/programs/global-pairing-rounds/${round.id}/rounds`}
          className='underline'
        >
          <span className='font-bold'>Rounds:</span>{' '}
          {showPercentage
            ? percentage(round.playedOrgsCount, round.orgsCount)
            : round.orgsCount}
        </Link>
        <span className='mx-3'>|</span>
        <span>
          <span className='font-bold'>Pairs:</span>{' '}
          {showPercentage
            ? percentage(round.completedPairingsCount, round.pairingsCount)
            : round.pairingsCount}
        </span>
        <span className='mx-3'>|</span>
        <span>
          <span className='font-bold'>Players:</span>{' '}
          {showPercentage
            ? percentage(round.completedPlayersCount, round.playersCount)
            : round.playersCount}
        </span>
        <span className='mx-3'>|</span>
        <span>
          <span className='font-bold'>Opt In/Out:</span>{' '}
          {participationStats?.optedIn ?? 0}/{participationStats?.optedOut ?? 0}
        </span>
        <span className='mx-3'>|</span>
        <Link
          to={`/admin/programs/global-pairing-rounds/${round.id}/repair-logs`}
          className='underline'
        >
          <span className='font-bold'>Repair Logs</span>
        </Link>
      </div>
      {showCreateMessageCampaignReminder && (
        <div className=' text-red-002'>Message Logic not yet created</div>
      )}
    </div>
  );
};

export const GlobalPairingRoundCard = (props: {
  round: GlobalPairingRound;
  actionSheet: React.ReactNode;
}): JSX.Element => {
  const { round, actionSheet } = props;

  return (
    <div className='w-full bg-dark-gray rounded-xl p-5 flex justify-between'>
      <div className='flex items-center gap-5'>
        <GlobalPairingRoundCover round={round} />

        <GlobalPairingRoundSummary round={round} />
      </div>
      <div>{actionSheet}</div>
    </div>
  );
};
