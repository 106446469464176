import { Link, useParams } from '@remix-run/react';

import { CampaignMessages } from '../MessageLogic/message';
import { GlobalPromotionSummary } from './Card';
import { useGlobalPromotion } from './hooks';

export const GlobalPromotionMessageList = (): JSX.Element => {
  const { id } = useParams<'id'>();

  const { data: globalPromotion } = useGlobalPromotion(id);

  return (
    <div className='w-full h-full text-white px-20'>
      <div className='text-2xl font-medium text-icon-gray mb-7'>
        <Link to={'../..'}>Global Promos Config</Link> \{' '}
        <Link to={'../'}>{globalPromotion?.title}</Link> \{' '}
        <span className='text-white'>Sent Messages</span>
      </div>

      {globalPromotion && (
        <GlobalPromotionSummary globalPromotion={globalPromotion} />
      )}

      <CampaignMessages
        params={{
          parentId: id,
        }}
      />
    </div>
  );
};
