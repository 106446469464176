import { Link } from '@remix-run/react';
import { useState } from 'react';

import { type PairingRound } from '../../../types';
import { CampaignMessages } from '../../MessageLogic/message';
import {
  GlobalPairingRoundSummary,
  useGlobalPairingRoundDetails,
} from '../GlobalRound';
import { PairingRoundsFilter } from '../Round';

export const PairingMessageList = (): JSX.Element => {
  const { globalRound } = useGlobalPairingRoundDetails();

  const [currentOrgId, setCurrentOrgId] = useState<string | null>(null);

  return (
    <div className='w-full h-full text-white px-20'>
      <div className='text-2xl font-medium text-icon-gray flex justify-between mb-7 mr-10'>
        <div>
          <Link to={`/admin/programs/global-pairing-rounds`}>Back</Link> /{' '}
          <span className='text-white'>Message Sent</span>
        </div>
        <PairingRoundsFilter
          globalRoundId={globalRound.id}
          onSelect={(round: PairingRound | null) => {
            setCurrentOrgId(round?.orgId ?? null);
          }}
        />
      </div>

      <GlobalPairingRoundSummary round={globalRound} />
      <CampaignMessages
        params={{
          orgId: currentOrgId ?? undefined,
          parentId: globalRound.id,
        }}
      />
    </div>
  );
};
