import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { useCallback, useMemo, useRef } from 'react';
import ReactDatePicker from 'react-datepicker';
import {
  Controller,
  FormProvider,
  useForm,
  useFormContext,
} from 'react-hook-form';

import { EnumsOrgSubscriptionStatus } from '@lp-lib/api-service-client/public';

import config from '../../config';
import { useAsyncCall } from '../../hooks/useAsyncCall';
import { getFeatureQueryParamString } from '../../hooks/useFeatureQueryParam';
import { useInstance } from '../../hooks/useInstance';
import { apiService } from '../../services/api-service';
import { MessageCampaignType } from '../../services/api-service/messageCampaign.api';
import { type UpdateMessageLogicRequest } from '../../services/api-service/messageLogic.api';
import { type MessageLogic, type Organization } from '../../types';
import {
  type GlobalPromotion,
  GlobalPromotionStatus,
} from '../../types/promotion';
import { err2s } from '../../utils/common';
import { DateUtils } from '../../utils/date';
import {
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { Loading } from '../Loading';
import { MessageCampaign } from '../MessageLogic';
import { OrganizationSelector, OrgUtils } from '../Organization';
import { usePromotions } from './hooks';

export interface GlobalPromotionFormData {
  id: string | null;
  title: string;
  startedAt: Date | null;
  endedAt: Date | null;
  messageCampaignId: string | null;
  orgIds: string[] | null;
}

const Header = (props: {
  mode: 'Create' | 'Edit';
  onCancel: () => void;
  isSubmitting: boolean;
  error: Error | null;
}): JSX.Element => {
  const { mode, onCancel, isSubmitting, error } = props;

  return (
    <header className='flex justify-between'>
      <h1 className='text-2xl font-medium'>{`${mode} Global Promotion`}</h1>

      <div className='flex flex-col gap-1'>
        <div className='flex justify-center items-center gap-4'>
          <button
            type='button'
            className='btn-secondary w-40 h-10'
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type='submit'
            className='btn-primary w-40 h-10'
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Saving' : 'Save'}
          </button>
        </div>
        {error && (
          <div className='text-sms font-normal text-red-002'>
            {err2s(error)}
          </div>
        )}
      </div>
    </header>
  );
};

const Title = (props: {
  globalPromotion: GlobalPromotion | null;
}): JSX.Element => {
  const { globalPromotion: promotion } = props;
  const {
    formState: { errors },
    register,
  } = useFormContext<GlobalPromotionFormData>();

  const disabled =
    !!promotion && promotion.status === GlobalPromotionStatus.Completed;

  return (
    <div>
      <h2 className='text-2xl font-medium mb-2.5'>Campaign Title</h2>

      <input
        disabled={disabled}
        className={`w-full h-12.5 ${
          errors.title ? 'field-error' : 'field'
        } mb-0`}
        maxLength={200}
        placeholder='Enter a title'
        {...register('title', {
          required: true,
          maxLength: 200,
        })}
      ></input>
      {errors.title && (
        <div className='w-full px-2 pt-1 text-left text-red-005 text-3xs h-3'>
          Must be 1 to 200 characters
        </div>
      )}
    </div>
  );
};

const StartDate = (props: { disabled: boolean; tz: string }): JSX.Element => {
  const { formState, control } = useFormContext<GlobalPromotionFormData>();

  return (
    <div className='w-70 flex flex-col gap-2'>
      <h3 className='text-base font-bold'>Start Date</h3>

      <Controller
        rules={{
          required: true,
        }}
        control={control}
        name='startedAt'
        render={({ field: { onChange, value } }) => (
          <ReactDatePicker
            className={`${
              formState.errors.startedAt ? 'field-error' : 'field'
            } h-15 mb-0`}
            selected={value ? utcToZonedTime(value, props.tz) : null}
            onChange={(date) =>
              onChange(date ? zonedTimeToUtc(date, props.tz) : null)
            }
            selectsStart
            minDate={new Date()}
            monthsShown={2}
            showTimeInput
            dateFormat='eee, MMM do yyyy h:mm aa'
            placeholderText='Set Date & Time'
            disabled={props.disabled}
          />
        )}
      />

      <input
        type='select'
        className='w-full field h-15 mb-0'
        value={DateUtils.FormatTimezone(props.tz)}
        disabled
      />
    </div>
  );
};

const EndDate = (props: { disabled: boolean; tz: string }): JSX.Element => {
  const { formState, control, watch } =
    useFormContext<GlobalPromotionFormData>();
  const startedAt = watch('startedAt');

  return (
    <div className='w-70 flex flex-col gap-2'>
      <h3 className='text-base font-bold'>End Date</h3>

      <Controller
        rules={{
          required: false,
          validate: (val) => {
            if (val && startedAt && val <= startedAt) {
              return 'The end date must be greater than the start date';
            }
            return;
          },
          deps: ['startedAt'],
        }}
        control={control}
        name='endedAt'
        render={({ field: { onChange, value } }) => (
          <ReactDatePicker
            className={`${
              formState.errors.endedAt ? 'field-error' : 'field'
            } h-15 mb-0`}
            selected={value ? utcToZonedTime(value, props.tz) : null}
            onChange={(date) =>
              onChange(date ? zonedTimeToUtc(date, props.tz) : null)
            }
            selectsEnd
            minDate={startedAt ? utcToZonedTime(startedAt, props.tz) : null}
            monthsShown={2}
            showTimeInput
            dateFormat='eee, MMM do yyyy h:mm aa'
            placeholderText='Set Date & Time'
            disabled={props.disabled}
          />
        )}
      />
      <input
        type='select'
        className='w-full field h-15 mb-0'
        value={DateUtils.FormatTimezone(props.tz)}
        disabled
      />
    </div>
  );
};

const MessageCampaignField = (props: {
  promotion: GlobalPromotion | null;
  onMessageTemplateChange: (data: UpdateMessageLogicRequest) => void;
}): JSX.Element => {
  const { control, watch } = useFormContext<GlobalPromotionFormData>();
  const startedAt = watch('startedAt');
  const parentId = watch('id');
  const messageCampaignId = watch('messageCampaignId');
  const selectDisabled =
    !!props.promotion &&
    props.promotion.status === GlobalPromotionStatus.Completed;

  return (
    <div>
      <Controller
        control={control}
        name='messageCampaignId'
        render={({ field: { onChange } }) => (
          <MessageCampaign
            parentId={parentId}
            campaignType={MessageCampaignType.GlobalPromotion}
            messageCampaignId={messageCampaignId}
            onChange={onChange}
            onMessageTemplateChange={props.onMessageTemplateChange}
            disabled={selectDisabled}
            checkLogicDisabled={(logic: MessageLogic) => {
              if (!props.promotion) return false;
              if (props.promotion.status === GlobalPromotionStatus.Completed)
                return true;
              if (
                logic.triggerType === 'relativeTimed' &&
                startedAt &&
                startedAt.getTime() +
                  logic.triggerDetails.delayedSeconds * 1000 <=
                  new Date().getTime()
              ) {
                return true;
              }
              return false;
            }}
          />
        )}
      />
    </div>
  );
};

const TimeRangeSection = (props: {
  globalPromotion: GlobalPromotion | null;
}): JSX.Element => {
  const tz = useInstance(() => getFeatureQueryParamString('toolkit-tz'));
  return (
    <section>
      <h2 className='text-2xl font-medium mb-6'>Time Range</h2>

      <main className='flex gap-4'>
        <StartDate
          disabled={
            !!props.globalPromotion &&
            props.globalPromotion.status === GlobalPromotionStatus.Completed
          }
          tz={tz}
        />
        <EndDate disabled={true} tz={tz} />
      </main>
    </section>
  );
};

const MessageLogicSection = (props: {
  globalPromotion: GlobalPromotion | null;
  onMessageTemplateChange: (data: UpdateMessageLogicRequest) => void;
}): JSX.Element => {
  return (
    <section>
      <h2 className='text-2xl font-medium mb-6'>Message Campaign</h2>

      <MessageCampaignField
        promotion={props.globalPromotion}
        onMessageTemplateChange={props.onMessageTemplateChange}
      />
    </section>
  );
};

function isOrgPromotable(organization: Organization) {
  if (
    organization.subscription.status ===
    EnumsOrgSubscriptionStatus.OrgSubscriptionStatusCancelled
  ) {
    return false;
  }
  return OrgUtils.IsSlackActive(organization.connection);
}

function compareOrganizations(a: Organization, b: Organization) {
  return (
    Number(b.id === config.misc.lunaParkOrgId) -
    Number(a.id === config.misc.lunaParkOrgId)
  );
}

function OrganizationSelectorSection(props: {
  globalPromotion: GlobalPromotion | null;
}): JSX.Element | null {
  const { globalPromotion } = props;
  const { data, isLoading } = usePromotions({
    globalPromotionId: globalPromotion?.id,
  });
  const selectedOrgIds = useMemo(
    () => (data ? data.map((p) => p.orgId) : []),
    [data]
  );

  const { setValue } = useFormContext<GlobalPromotionFormData>();

  if (isLoading) return <Loading />;

  return (
    <OrganizationSelector
      initOrgIds={selectedOrgIds}
      maxTableHeight='max-h-90'
      columns={{
        names: ['MEMBERS'],
        render: (org) => [org.organizersCount],
      }}
      onChange={(change) => {
        setValue('orgIds', change.orgIds);
      }}
      disabled={!!globalPromotion}
      filterOrganization={isOrgPromotable}
      compareOrganizations={compareOrganizations}
    />
  );
}

export const GlobalPromotionEditor = (props: {
  globalPromotion: GlobalPromotion | null;
  onCancel: () => void;
  onSave: (data: GlobalPromotionFormData) => Promise<void>;
}): JSX.Element => {
  const { globalPromotion, onCancel, onSave } = props;
  const triggerConfirmCancelModal = useAwaitFullScreenConfirmCancelModal();

  const {
    state: { transformed: state },
    error,
    call,
  } = useAsyncCall(
    useCallback(
      (data: GlobalPromotionFormData) => {
        return onSave(data);
      },
      [onSave]
    )
  );

  const formReturned = useForm<GlobalPromotionFormData>({
    defaultValues: {
      id: globalPromotion?.id ?? null,
      title: globalPromotion?.title ?? '',
      startedAt: globalPromotion ? new Date(globalPromotion.startedAt) : null,
      endedAt: null,
      messageCampaignId: globalPromotion?.messageCampaignId ?? null,
      orgIds: [],
    },
  });

  const updatedMessageLogicsRef = useRef<UpdateMessageLogicRequest[]>([]);
  const onSubmit = formReturned.handleSubmit(
    async (data: GlobalPromotionFormData) => {
      if (!data.startedAt) return;

      if (!data.id) {
        const confirmCancel = await triggerConfirmCancelModal({
          kind: 'confirm-cancel',
          prompt: (
            <ConfirmCancelModalText className='text-2xl font-medium'>
              <div>
                <div className='p-4'>
                  Are You Sure You Selected the Correct Recipients?
                </div>
                <div className='text-sm'>
                  Please confirm that this Promotion should target the following
                  recipients:
                </div>
                <div className='text-sm my-5'>
                  {data.orgIds === null ? 'All' : data.orgIds.length}{' '}
                  Organizations
                </div>
              </div>
            </ConfirmCancelModalText>
          ),
          confirmBtnVariant: 'primary',
          confirmBtnLabel: 'Confirm',
          cancelBtnLabel: 'Cancel',
        });
        if (confirmCancel.result === 'canceled') return;
      }

      if (updatedMessageLogicsRef.current.length > 0) {
        Promise.all(
          updatedMessageLogicsRef.current.map((req) =>
            apiService.messageLogic.update(req)
          )
        );
      }

      call(data);
    }
  );

  return (
    <FormProvider {...formReturned}>
      <form className='w-full px-10 text-white' onSubmit={onSubmit}>
        <Header
          mode={!props.globalPromotion ? 'Create' : 'Edit'}
          onCancel={onCancel}
          isSubmitting={state.isRunning}
          error={error}
        />

        <main className='mt-10 flex gap-8'>
          <div className='flex flex-col gap-8'>
            <Title globalPromotion={globalPromotion} />

            <TimeRangeSection globalPromotion={globalPromotion} />

            <MessageLogicSection
              globalPromotion={globalPromotion}
              onMessageTemplateChange={(data) =>
                updatedMessageLogicsRef.current.push(data)
              }
            />
          </div>

          <OrganizationSelectorSection globalPromotion={globalPromotion} />
        </main>
      </form>
    </FormProvider>
  );
};
