import { Navigate, Route, Routes } from '@remix-run/react';
import { type ReactNode } from 'react';

import {
  CelebrationAdminPanelConfig,
  CelebrationAdminPanelList,
} from '../../components/Celebrations';
import {
  GlobalPromotionCreate,
  GlobalPromotionEdit,
  GlobalPromotionList,
} from '../../components/GlobalPromotion';
import { GlobalPromotionMessageList } from '../../components/GlobalPromotion/MessageList';
import { PromotionList } from '../../components/GlobalPromotion/PromotionList';
import {
  GlobalPairingRoundCreate,
  GlobalPairingRoundDetailsLayout,
  GlobalPairingRoundEdit,
  GlobalPairingRoundList,
} from '../../components/Pairing/GlobalRound';
import { PairingMessageList } from '../../components/Pairing/Message/List';
import { PairingRepairLogList } from '../../components/Pairing/RepairLog';
import { PairingRoundListInGlobalRound } from '../../components/Pairing/Round';
import { ProgramLinksList } from '../../components/Program/ProgramLinkList';
import { useTitle } from '../../hooks/useTitle';
import { makeTitle } from '../../utils/common';
import { AdminView } from './AdminView';
import { Header, type NavItem } from './Header';

function Wrapper(props: { children?: ReactNode }): JSX.Element {
  const links: Record<string, NavItem> = {
    Programs: '/admin/programs/v2',
    'Global Pairing Tournaments': '/admin/programs/global-pairing-rounds',
    'Global Promos Config': '/admin/programs/global-promotions',
    'Birthdays and Anniversaries': '/admin/programs/celebrations',
    Analytics: {
      'Installed Programs': '/admin/programs/links',
      'Birthdays & Anniversaries': '/admin/programs/celebrations/list',
    },
  };
  return (
    <div className='w-full h-full'>
      <div className='py-10 px-10'>
        <Header links={links} />
      </div>
      {props.children}
    </div>
  );
}

function AdminPrograms(): JSX.Element | null {
  useTitle(makeTitle('Programs'));
  return (
    <AdminView>
      <Wrapper>
        <Routes>
          <Route index element={null} />
          {/* <Route index element={<Navigate to={'./templates'} replace />} /> */}
          <Route path='links' element={<ProgramLinksList />}></Route>
          <Route path='global-pairing-rounds'>
            <Route index element={<Navigate to={'list'} replace />} />
            <Route path='list' element={<GlobalPairingRoundList />} />
            <Route path='create' element={<GlobalPairingRoundCreate />} />
            <Route path=':id' element={<GlobalPairingRoundDetailsLayout />}>
              <Route index element={<Navigate to={'edit'} replace />} />
              <Route path='edit' element={<GlobalPairingRoundEdit />} />
              <Route
                path='rounds'
                element={<PairingRoundListInGlobalRound />}
              />
              <Route path='messages' element={<PairingMessageList />} />
              <Route path='repair-logs' element={<PairingRepairLogList />} />
            </Route>
          </Route>
          <Route path='global-promotions'>
            <Route index element={<Navigate to={'list'} replace />} />
            <Route path='list' element={<GlobalPromotionList />} />
            <Route path='create' element={<GlobalPromotionCreate />} />
            <Route path=':id'>
              <Route index element={<Navigate to={'edit'} replace />} />
              <Route path='edit' element={<GlobalPromotionEdit />} />
              <Route path='promotions' element={<PromotionList />} />
              <Route path='messages' element={<GlobalPromotionMessageList />} />
            </Route>
          </Route>
          <Route path='celebrations'>
            <Route index element={<Navigate to={'config'} replace />} />
            <Route path='config' element={<CelebrationAdminPanelConfig />} />
            <Route path='list' element={<CelebrationAdminPanelList />} />
          </Route>
        </Routes>
      </Wrapper>
      {/* <Routes>
        <Route path='templates'>
          <Route index element={<Navigate to={'list'} replace />} />
          <Route path='list' element={<ProgramTemplateList />} />
          <Route path=':id'>
            <Route index element={<Navigate to={'edit'} replace />} />
            <Route path='edit' element={<ProgramTemplateEditor />} />
          </Route>
        </Route>
      </Routes> */}
    </AdminView>
  );
}

// eslint-disable-next-line import/no-default-export
export default AdminPrograms;
