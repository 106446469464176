import 'react-datepicker/dist/react-datepicker.css';

import { useNavigate, useParams } from '@remix-run/react';
import { useCallback } from 'react';
import useSWR from 'swr';

import { apiService } from '../../services/api-service';
import { err2s } from '../../utils/common';
import {
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { Loading } from '../Loading';
import { GlobalPromotionEditor, type GlobalPromotionFormData } from './Editor';

export function useDeleteGlobalPromotion(): (id: string) => Promise<boolean> {
  const triggerConfirmCancelModal = useAwaitFullScreenConfirmCancelModal();

  return useCallback(
    async (id: string) => {
      const confirmCancel = await triggerConfirmCancelModal({
        kind: 'confirm-cancel',
        prompt: (
          <ConfirmCancelModalText className='text-2xl font-medium'>
            Are you sure you want to delete this Global Promotion?
          </ConfirmCancelModalText>
        ),
        confirmBtnLabel: 'Confirm',
        cancelBtnLabel: 'Cancel',
      });
      if (confirmCancel.result === 'canceled') return false;

      const resp = await apiService.promotion.deleteGlobalPromotion(id);
      if (!resp) return false;

      return true;
    },
    [triggerConfirmCancelModal]
  );
}

export function GlobalPromotionCreate(): JSX.Element {
  const navigate = useNavigate();

  const handleSave = async (data: GlobalPromotionFormData) => {
    if (!data.startedAt) return;

    await apiService.promotion.createGlobalPromotion({
      title: data.title,
      startedAt: data.startedAt.toJSON(),
      endedAt: data.endedAt?.toJSON() ?? null,
      messageCampaignId: data.messageCampaignId,
      orgIds: data.orgIds,
    });

    navigate(-1);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  return (
    <GlobalPromotionEditor
      globalPromotion={null}
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
}

export function GlobalPromotionEdit(): JSX.Element | null {
  const navigate = useNavigate();
  const { id } = useParams<'id'>();

  const { isValidating, data, error } = useSWR(
    ['/global-pairing-rounds', id],
    async () =>
      await (
        await apiService.promotion.getGlobalPromotionById(id ?? '')
      ).data
  );

  const handleSave = async (formData: GlobalPromotionFormData) => {
    if (!formData || !formData.id) return;

    const resp = await apiService.promotion.updateGlobalPromotion(formData.id, {
      title: formData.title,
      startedAt: formData.startedAt?.toJSON(),
      messageCampaignId: formData.messageCampaignId,
    });
    if (!resp) return;

    navigate(-1);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  if (isValidating) {
    return <Loading />;
  }
  if (error) {
    return (
      <div className='text-center text-white'>
        Get Round Err: {err2s(error)}
      </div>
    );
  }
  if (!data) {
    return <div className='text-center text-white'>Not Found</div>;
  }
  return (
    <GlobalPromotionEditor
      globalPromotion={data.globalPromotion}
      onSave={handleSave}
      onCancel={handleCancel}
    />
  );
}
