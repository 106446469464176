import { useNavigate } from '@remix-run/react';
import { format } from 'date-fns';
import capitalize from 'lodash/capitalize';
import { useMemo } from 'react';
import useSWR from 'swr';

import { apiService } from '../../../services/api-service';
import { err2s } from '../../../utils/common';
import { Loading } from '../../Loading';

export function CelebrationAdminPanelList(): JSX.Element | null {
  const { data: celebrations, error } = useSWR(
    `/celebrations`,
    async () =>
      (await apiService.celebration.allCelebrations()).data.celebrations
  );
  const navigate = useNavigate();

  const sorted = useMemo(
    () =>
      celebrations?.sort((a, b) => {
        const l = `${a.organization?.name ?? ''}-${a.channel?.name ?? ''}`;
        const r = `${b.organization?.name ?? ''}-${b.channel?.name ?? ''}`;
        return l.localeCompare(r);
      }),
    [celebrations]
  );

  if (error) return <div className=' text-red-002'>{err2s(error)}</div>;
  if (!sorted) return <Loading />;

  return (
    <div className='w-full px-23.5 text-white'>
      <header className='text-2xl font-medium'>Birthday & Anniversaries</header>

      <div className='my-8 w-full overflow-x-auto scrollbar'>
        <table className='table'>
          <thead>
            <tr className='whitespace-nowrap'>
              <th className='hidden'>Program ID</th>
              <th className='pl-2'>Organization</th>
              <th>Channel</th>
              <th>Owner</th>
              <th>Created at</th>
              <th>Status</th>
              <th>Birthdays On</th>
              <th>Anniversaries On</th>
              <th>Users in Channel</th>
              <th>Birthday Populated</th>
              <th>Anniversaries Populated</th>
              <th>Users w/ Celebrate On</th>
              <th>Message Cadence</th>
            </tr>
          </thead>
          <tbody>
            {sorted.map((celebration) => (
              <tr
                key={celebration.id}
                className='whitespace-nowrap cursor-pointer'
                onClick={() => {
                  navigate(
                    `/admin/organizations/${celebration.organization?.id}/programs/${celebration.programLink?.id}`
                  );
                }}
              >
                <td className='hidden'>{celebration.programLink?.id}</td>
                <td className='pl-2'>{celebration.organization?.name}</td>
                <td>#{celebration.channel?.name}</td>
                <td>
                  {celebration.owner?.firstName} {celebration.owner?.lastName}
                </td>
                <td>
                  {format(
                    new Date(celebration.createdAt),
                    'MM/dd/yyyy hh:mm:ss'
                  )}
                </td>
                <td>{capitalize(String(celebration.programLink?.status))}</td>
                <td>
                  {capitalize(
                    String(celebration.messageSettings.birthday.enabled)
                  )}
                </td>
                <td>
                  {capitalize(
                    String(celebration.messageSettings.workAnniversary.enabled)
                  )}
                </td>
                <td>{celebration.channel?.membersCount}</td>
                <td>{celebration.stats.birthdaysCount}</td>
                <td>{celebration.stats.anniversariesCount}</td>
                <td>{celebration.stats.celebrateBirthdaysCount}</td>
                <td>{capitalize(celebration.messageSettings.frequency)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
