import { Link } from '@remix-run/react';
import useSWR from 'swr';

import { apiService } from '../../../services/api-service';
import { err2s } from '../../../utils/common';
import { Loading } from '../../Loading';
import { useGlobalPairingRoundDetails } from '../GlobalRound';

function LogTable(props: { globalRoundId: string }): JSX.Element {
  const { globalRoundId } = props;
  const {
    data: logs,
    error,
    isValidating,
  } = useSWR(
    `/global-pairing-rounds/${globalRoundId}/repair-logs`,
    async () => {
      const resp = await apiService.pairing.queryGlobalRoundRepairLogs(
        globalRoundId
      );
      return resp.data.logs;
    }
  );

  if (isValidating) return <Loading />;

  if (error) return <div className='text-red-002'>{err2s(error)}</div>;

  if (!logs?.length) return <div className='text-secondary'>No logs found</div>;

  return (
    <table className='w-full text-white text-left'>
      <thead>
        <tr>
          <th>Round Id</th>
          <th>Repaired At</th>
          <th>Detail</th>
        </tr>
      </thead>
      <tbody>
        {logs.map((log) => (
          <tr key={log.id}>
            <th>{log.roundId}</th>
            <th>{log.createdAt}</th>
            <th>
              <pre className='bg-secondary px-2 text-2xs'>
                {log.data && JSON.stringify(log.data, null, 2)}
              </pre>
            </th>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export function PairingRepairLogList(): JSX.Element {
  const { globalRound } = useGlobalPairingRoundDetails();

  return (
    <div className='w-full h-full px-10 flex flex-col gap-5'>
      <div className='text-2xl font-medium text-icon-gray'>
        <Link to={'/admin/programs/global-pairing-rounds'}>
          Global Pairs Config
        </Link>{' '}
        \ <Link to={'../'}>{globalRound.mainGamePack?.name}</Link> \{' '}
        <span className='text-white'>Logs</span>
      </div>

      <LogTable globalRoundId={globalRound.id} />
    </div>
  );
}
