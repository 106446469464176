import { Link, useNavigate } from '@remix-run/react';

import { type GlobalPromotion } from '../../types/promotion';
import { type Action, ActionSheet } from '../ActionSheet';
import { ErrorMessage } from '../Game/GameCenter';
import { ChatSendIcon } from '../icons/Chat/ChatSendIcon';
import { DeleteIcon } from '../icons/DeleteIcon';
import { EditIcon } from '../icons/EditIcon';
import { Loading } from '../Loading';
import { GlobalPromotionCard } from './Card';
import { useDeleteGlobalPromotion } from './CRUD';
import { useGlobalPromotions } from './hooks';

function GlobalPromotionActionSheet({
  globalPromotion,
  onDelete,
}: {
  globalPromotion: GlobalPromotion;
  onDelete: (round: GlobalPromotion) => void;
}) {
  const navigate = useNavigate();

  const actions: Action<string>[] = [
    {
      kind: 'button',
      key: 'edit',
      icon: <EditIcon />,
      text: 'Edit Promotion',
      onClick: () => {
        navigate(`../${globalPromotion.id}/edit`);
      },
    },
    {
      kind: 'button',
      key: 'messages',
      icon: <ChatSendIcon />,
      text: 'Sent Messages',
      onClick: () => {
        navigate(`../${globalPromotion.id}/messages`);
      },
    },
    {
      kind: 'button',
      key: 'delete',
      icon: <DeleteIcon />,
      text: 'Delete',
      className: 'text-red-002',
      onClick: () => onDelete(globalPromotion),
    },
  ];

  return (
    <ActionSheet
      actions={actions}
      btnSizingClassName='w-10 h-10'
      placement='bottom'
    />
  );
}

export const GlobalPromotionList = (): JSX.Element => {
  const deleteGlobalRound = useDeleteGlobalPromotion();

  const {
    data: globalPromotions,
    isLoading,
    error,
    mutate,
  } = useGlobalPromotions();

  const handleDelete = async (globalPromotion: GlobalPromotion) => {
    const isDeleted = await deleteGlobalRound(globalPromotion.id);
    if (!isDeleted) return;
    mutate();
  };

  if (isLoading) return <Loading />;
  if (error)
    return (
      <ErrorMessage text='Something went wrong' handleRetry={() => mutate()} />
    );

  return (
    <div className='px-10 pb-10 text-white'>
      <header className='flex justify-between items-center'>
        <div className='text-2xl font-medium'>Global Promotions Config</div>
        <Link
          to='../create'
          className='btn-primary w-45 h-12.5 flex justify-center items-center'
        >
          Create Promotion
        </Link>
      </header>

      <section className='mt-10 mx-30'>
        {globalPromotions && globalPromotions.length > 0 ? (
          <div className='flex flex-col gap-5 items-center'>
            {globalPromotions.map((globalPromotion: GlobalPromotion) => (
              <GlobalPromotionCard
                key={globalPromotion.id}
                globalPromotion={globalPromotion}
                actionSheet={
                  <GlobalPromotionActionSheet
                    globalPromotion={globalPromotion}
                    onDelete={handleDelete}
                  />
                }
              />
            ))}
          </div>
        ) : (
          <div className='w-full text-center text-secondary'>
            No Global Promotions
          </div>
        )}
      </section>
    </div>
  );
};
