import { Link, useNavigate } from '@remix-run/react';

import {
  type GlobalPairingRound,
  GlobalPairingRoundStatus,
} from '../../../types';
import { type Action, ActionSheet } from '../../ActionSheet';
import { ErrorMessage } from '../../Game/GameCenter';
import { ChatSendIcon } from '../../icons/Chat/ChatSendIcon';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { EditIcon } from '../../icons/EditIcon';
import { InfoIcon } from '../../icons/InfoIcon';
import { Loading } from '../../Loading';
import { GlobalPairingRoundCard } from './Card';
import {
  useDeleteGlobalPairingRound,
  useGlobalRounds,
  useRepairGlobalPairingRound,
} from './CRUD';

const GlobalPairingRoundActionSheet = ({
  round,
  onDelete,
  onRepair,
}: {
  round: GlobalPairingRound;
  onDelete: (round: GlobalPairingRound) => void;
  onRepair: (round: GlobalPairingRound) => void;
}): JSX.Element => {
  const navigate = useNavigate();

  const actions: Action<string>[] = [
    {
      kind: 'button',
      key: 'edit',
      icon: <EditIcon />,
      text: 'Edit Round',
      onClick: () => {
        navigate(`../${round.id}/edit`);
      },
    },
    {
      kind: 'button',
      key: 'messages',
      icon: <ChatSendIcon />,
      text: 'Sent Messages',
      onClick: () => {
        navigate(`../${round.id}/messages`);
      },
      disabled: !round.messageCampaignId,
    },
    {
      kind: 'button',
      key: 'repair',
      icon: <InfoIcon />,
      text: 'Repair Pairings',
      onClick: () => onRepair(round),
      disabled: round.status !== GlobalPairingRoundStatus.InProgress,
    },
    {
      kind: 'button',
      key: 'delete',
      icon: <DeleteIcon />,
      text: 'Delete',
      className: 'text-red-002',
      onClick: () => onDelete(round),
      disabled: round.completedPairingsCount > 0,
    },
  ];

  return (
    <ActionSheet
      actions={actions}
      btnSizingClassName='w-10 h-10'
      placement='bottom'
    />
  );
};

export const GlobalPairingRoundList = (): JSX.Element => {
  const { globalRounds, isLoading, error, reload, del } = useGlobalRounds();
  const deleteGlobalRound = useDeleteGlobalPairingRound();
  const repairGlobalRound = useRepairGlobalPairingRound();
  const navigate = useNavigate();

  const handleDelete = async (globalRound: GlobalPairingRound) => {
    const isDeleted = await deleteGlobalRound(globalRound.id);
    if (!isDeleted) return;
    del(globalRound.id);
  };

  const handleRepair = async (globalRound: GlobalPairingRound) => {
    const isRepaired = await repairGlobalRound(globalRound.id);
    if (!isRepaired) return;
    navigate(
      `/admin/programs/global-pairing-rounds/${globalRound.id}/repair-logs`
    );
  };

  return (
    <div className='px-10 pb-10 text-white'>
      <header className='flex justify-between items-center'>
        <div className='text-2xl font-medium'>Global Pairs Config</div>
        <Link
          to='../create'
          className='btn-primary w-45 h-12.5 flex justify-center items-center'
        >
          Create Round
        </Link>
      </header>

      <section className='mt-10 mx-30'>
        <div className='flex flex-col gap-5 items-center'>
          {globalRounds?.map((globalRound) => (
            <GlobalPairingRoundCard
              key={globalRound.id}
              round={globalRound}
              actionSheet={
                <GlobalPairingRoundActionSheet
                  round={globalRound}
                  onDelete={handleDelete}
                  onRepair={handleRepair}
                />
              }
            />
          ))}
        </div>
      </section>

      {isLoading && <Loading />}
      {error && (
        <div className='w-full flex items-center justify-center'>
          <ErrorMessage text='Something went wrong' handleRetry={reload} />
        </div>
      )}
      {globalRounds?.length === 0 && (
        <div className='w-full mt-50 flex items-center justify-center text-secondary'>
          No Global Pairs Config
        </div>
      )}
    </div>
  );
};
